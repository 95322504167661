<template>
  <div v-if="category">
    <div
      class="left-right-margins hasBorderBottom"
      v-for="item in availableProducts"
      v-bind:key="item.id"
    >
      <MenuItem
        :isCategoryOpen="isCategoryOpen"
        :item="item"
        :membershipEnabledForRestaurant="membershipEnabledForRestaurant"
        :membershipPricesVisible="membershipPricesVisible"
        :isInteractiveCatalogue="isInteractiveCatalogue"
        :userHasMembership="userHasMembership"
        :storeIsHotel="storeIsHotel"
      ></MenuItem>
    </div>
    <div
      v-if="unavailableProducts.length > 0 && !clickedToSeeUnavailable"
      class="left-right-margins"
    >
      <div class="unavailable-items-container" @click="showUnavailableItems">
        <div class="gray-overlay"></div>
        <div class="unavailable-items-button">
          <div class="unaivailble-items-title">
            <h3>{{unavailableProducts.length}} {{labels.menu.unavailanbleItems[language]}}</h3>
            <h4
              v-if="!isCategoryOpen"
            >{{labels.menu.servingHours[language]}} {{category.startTime.slice(0, -3)}}-{{category.endTime.slice(0, -3)}}</h4>
          </div>
          <div class="unaivailble-items-description">
            <div>{{labels.menu.seeUnavailableItems[language]}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="unavailableProducts.length > 0 && clickedToSeeUnavailable">
      <div
        class="left-right-margins hasBorderBottom"
        v-for="item in unavailableProducts"
        v-bind:key="item.id"
      >
        <MenuItem
          :isCategoryOpen="isCategoryOpen"
          :item="item"
          :membershipEnabledForRestaurant="membershipEnabledForRestaurant"
          :membershipPricesVisible="membershipPricesVisible"
          :storeIsHotel="storeIsHotel"
          :happyHourDiscountPercentage="happyHourDiscountPercentage"
          :isInHappyHour="isInHappyHour"
        ></MenuItem>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  name: "CategoryProducts",
  components: {
    MenuItem,
  },
  data() {
    return {
      clickedToSeeUnavailable: false,
    };
  },
  props: ["category", "membershipEnabledForRestaurant", "membershipPricesVisible", "userHasMembership", "storeIsHotel", "happyHourDiscountPercentage", "isInHappyHour", "currentGreekDate", "isInteractiveCatalogue"],
  computed: {
    availableProducts() {
      if (!this.isCategoryOpen) {
        return [];
      };
      return this.category.products.filter((product) => product.available);
    },

    unavailableProducts() {
      if (!this.isCategoryOpen) {
        return this.category.products;
      }
      return this.category.products.filter((product) => !product.available);
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    isCategoryOpen() {
      if (!this.category.startTime || !this.category.endTime || this.currentGreekDate == '' || this.currentGreekDate == undefined) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isCategoryOpen = true;

      if (this.category.startTime < this.category.endTime) {
        isCategoryOpen =
          this.category.startTime < currentTime &&
          this.category.endTime > currentTime;
      } else {
        isCategoryOpen =
          this.category.startTime < currentTime ||
          this.category.endTime > currentTime;
      }

      return isCategoryOpen;
    },
  },
  methods: {
    showUnavailableItems() {
      this.clickedToSeeUnavailable = true;
    },
  },
};
</script>

<style scoped>
.hasBorderBottom {
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
}

.left-right-margins {
  margin-left: 4%;
  margin-right: 4%;
}

.unavailable-items-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2% 0 2% 0;
  cursor: pointer;
  z-index: 1;
}

.unavailable-items-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #414551;
  font-weight: 500;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.unavailable-items {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background-color: #bdbdbd;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
}

.gray-overlay {
  position: absolute;
  top: -7.5%;
  left: -10px;
  width: 100%;
  height: 115%;
  border-radius: 10px;
  background: rgba(180, 180, 180, 0.2);
  z-index: 2;
}

.unaivailble-items-title > h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.unaivailble-items-title > h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.unaivailble-items-description {
  color: gray;
  font-size: 13px;
  font-weight: 400;
  padding-right: 15%;
  white-space: pre-wrap;
}

@media screen and (max-width: 1285px) {
  .gray-overlay {
    left: 0;
    top: -5%;
    width: 100%;
    height: 110%;
    border-radius: 10px;
    background: rgba(180, 180, 180, 0.2);
    z-index: 2;
  }

  .unavailable-items-container {
    margin: 4% 0 4% 0;
  }

  .unavailable-items-button {
    padding-right: 3%;
    padding-left: 3%;
  }

  .unavailable-items {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
  }

  .unaivailble-items-title > h3 {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
  }

  .unaivailble-items-title > h4 {
    font-size: 13px;
    font-weight: 500;
    padding-right: 5px;
  }

  .unaivailble-items-description {
    font-size: 12px;
    font-weight: normal;
  }
  .left-right-margins {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}

@media (max-width: 300px) {
  .gray-overlay {
    height: 105%;
    top: -2.5%;
  }
}
</style>