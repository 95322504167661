<template>
  <div id="menu-screen" translate="no">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :height="40"
      loader="spinner"
      color="#003340"
    ></Loading>
    <ContestModal :class="{ hide: !isContestModalOpen }"></ContestModal>
    <BackToServices
      v-if="(store) || (store && menu && menu.restaurant && !menu.restaurant.goToMenuFromServicesScreen) || tableHasRedirectBackLink || isPdaAndHasID"
      :table="tableId"
      :storeName="storeName"
      :isApplication="isApplication"
      :redirectBackTo="redirectBackTo"
      :hasBoxShadow="menu.restaurant && menu.restaurant.type == 'INFO'"
      :restaurantType="menu.restaurant.type"
      :amountOfDepartments="store.amountOfDepartments"
      id="back-to-services"
    ></BackToServices>
    <CategoriesNavbar
      id="categories-navbar"
      v-if="
        $screen.width <= 1285 &&
        categoriesToShow.length != 0 &&
        menu.restaurant &&
        (menu.restaurant.type == 'MENU' || menu.restaurant.type == 'REQUESTS')
      "
      :multipleServices="store || (store && menu && menu.restaurant && !menu.restaurant.goToMenuFromServicesScreen) || tableHasRedirectBackLink || isPdaAndHasID"
    ></CategoriesNavbar>
    <div id="banner">
      <img v-if="store" id="banner-image" :src="`${bannerImage}`" alt />

      <div id="store-info">
        <Store id="store" :store="store" :shouldShowMenu="false"></Store>
      </div>
    </div>
    <div id="body">
      <div
        v-if="
          $screen.width > 1285 &&
          menu.restaurant &&
          (menu.restaurant.type == 'MENU' || menu.restaurant.type == 'REQUESTS')
        "
        id="categories"
      >
        <Categories categoriesType="menu"></Categories>
      </div>
      <div id="food-items">
        <ContestRibbon v-if="showContestRibbon"></ContestRibbon>
        <CustomHeaderBanner
          v-if="store && !showFluterBanner && menu.restaurant.names.translation[language].customHeaderBannerText!=null && menu.restaurant.names.translation[language].customHeaderBannerText!=''"
          :restaurantNames="menu.restaurant.names"
          :showFluterBanner="showFluterBanner"
        ></CustomHeaderBanner>
        <FluterDiscountBanner
          v-if="showFluterBanner"
          :deliveryType="deliveryType"
          :isApplication="isApplication"
          :restaurantNames="menu.restaurant.names"
          :storeNames="store.names"
          :serviceStartTime="serviceStartTime"
          :serviceEndTime="serviceEndTime"
          :pricingPackage="store.pricingPackage"
          :isServiceOpen="isServiceOpen"
          :shouldShowOnlyScheduledOrders="shouldShowOnlyScheduledOrders"
          :isOpenForScheduledButNotInCurrentTime="isOpenForScheduledButNotInCurrentTime"
          :scheduledOrdersStartTime="scheduledOrdersStartTime"
          :scheduledOrdersEndTime="scheduledOrdersEndTime"
          :isDelayedResponse="menu.restaurant.delayedResponse"
          :minimumPriceForDeliveryDiscount="minimumPriceForDeliveryDiscount"
          :isRoomServiceCharged="isRoomServiceCharged"
          :typeOfRoomServiceCharge="typeOfRoomServiceCharge"
          :roomServiceCharge="roomServiceCharge"
        ></FluterDiscountBanner>
        <div
          v-if="menu && menu.restaurant && (menu.restaurant.type == 'MENU' || menu.restaurant.type == 'REQUESTS')"
          id="menu-container"
          class="menu minHeight"
        >
          <div
            v-for="category in categoriesToShow"
            :id="category.names.translation['ENG'].name.replace(' ', '-')"
            v-bind:key="category.id"
          >
            <div id="category-label-container">
              <h3 class="category-label">
                {{ getCategoryName(category) }}
                <span
                  id="category-schedule"
                  style="margin-left: 5px"
                  v-if="category.startTime"
                >
                  ({{ formattedTime(category.startTime) }} -
                  {{ formattedTime(category.endTime) }})
                </span>
              </h3>
              <div v-if="hasCategoryDescription(category)" class="category-description-container">
                <font-awesome-icon class="category-description-icon" icon="info" />
                <h4 id="category-description">{{ getCategoryDescription(category) }}</h4>
              </div>
              <div
                v-if="category.maxProductsPerCartForThisCategory!=null && category.maxProductsPerCartForThisCategory >0 "
                class="category-description-container"
              >
                <font-awesome-icon
                  class="category-description-icon"
                  icon="sliders-h"
                  style="margin-top: 5px !important;"
                />
                <h4
                  id="category-description"
                >{{ labels.menu.selectUpTo[language] }} {{category.maxProductsPerCartForThisCategory}} {{ labels.menu.productsFromCategory[language] }}</h4>
              </div>
            </div>

            <div
              v-if="menu.restaurant && (menu.restaurant.type == 'MENU' || menu.restaurant.type == 'REQUESTS')"
            >
              <CategoryProducts
                :category="category"
                :membershipEnabledForRestaurant="menu.restaurant.membershipEnabled"
                :userHasMembership="userHasMembership"
                :membershipPricesVisible="menu.restaurant.membershipPricesVisible"
                :storeIsHotel="store.hotel"
                :happyHourDiscountPercentage="0"
                :currentGreekDate="currentGreekDate"
                :isInteractiveCatalogue="isInteractiveCatalogue"
                :isInHappyHour="false"
              />
            </div>
          </div>
        </div>
        <div
          id="menu-container"
          class="menu"
          v-else-if="menu.restaurant && menu.restaurant.type == 'INFO'"
        >
          <h3 class="department-info-title">{{ menu.restaurant.names.translation[language].name }}</h3>
          <div
            :class="{
              hasBorderBottom: true,
              addMargin: true,
            }"
          >
            <div
              class="message-container"
              style="display: flex; flex-direction: column"
              v-html="menu.restaurant.names.translation[language].infoText"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$screen.width > 1285 && isViewOnly !== true"
      v-bind:class="{
        openCartDetails: isCartPreviewOpen,
        closeCartDetails: !isCartPreviewOpen && hasCartPreviewOpened,
      }"
      id="current-order"
    >
      <h4 id="to-kalathi-mou">{{ labels.cart.myCart[language] }}</h4>
      <div id="total-cost">
        <h6>
          {{ labels.cart.total[language] }}:
          <span>{{ totalCost }}€</span>
        </h6>
      </div>
      <CartItem :item="item" v-for="item in cartItems" :key="item.id"></CartItem>
      <div id="send-order">
        <Button
          @click="goToCartScreen"
          :class="{ grayOut: cartSize == 0 }"
          id="send-order-button"
        >{{ labels.cart.completeOrder[language] }}</Button>
      </div>
    </div>
    <div id="upper-bar">
      <div id="logo-container" v-if="!isApplication">
        <a id="link-to-landing" href="/">
          <img id="logo" src="../../assets/Full_logo-Black.svg" alt />
        </a>
      </div>
      <div id="profile-and-cart">
        <LanguageSelect
          v-if="isAbleToChangeLanguage"
          id="language-select"
          :class="{ 'language-position-in-login': isLoggedIn }"
        ></LanguageSelect>

        <ProfileDropdown
          v-if="isLoggedIn && tableType!='SELF_SERVICE_POS'"
          id="profile-dropdown"
          :isApplication="isApplication"
          :class="{ 'profile-dropdown-with-lang-select': isAbleToChangeLanguage,
                    'profile-dropdown-without-lang-select': !isAbleToChangeLanguage }"
        ></ProfileDropdown>
        <EnterPrompt
          v-else-if="shouldShowEnterPrompt && !isLoggedIn"
          @enter-clicked="initiateEntrance"
          id="enter-prompt"
          :class="{ 'enter-prompt-with-lang-select': isAbleToChangeLanguage,
                    'enter-prompt-without-lang-select': !isAbleToChangeLanguage }"
        ></EnterPrompt>
        <div
          v-if="$screen.width > 1285 && isViewOnly !== true && isInteractiveCatalogue && cartSize != 0"
          @click="goToCartScreen"
          id="cart-background"
        >
          <Cart :itemCount="cartSize" id="cart"></Cart>
        </div>
      </div>
    </div>
    <div
      @click="onMaskClick"
      :class="{
        mask:
          isModalOpen ||
          areMobileCategoriesOpen ||
          isEntranceOpen ||
          isLanguageModalOpen ||
          isContestModalOpen ||
          isCategoriesModalOpen
      }"
    ></div>
    <div
      :class="{
        showMobileCart: false,
        hideMobileCart: false,
      }"
      v-if="
        $screen.width <= 1285 &&
        isViewOnly !== true &&
        menu.restaurant &&
        (menu.restaurant.type == 'MENU' || menu.restaurant.type == 'REQUESTS')
        && isInteractiveCatalogue
      "
      id="mobile-cart"
    >
      <Button
        @click="goToCartScreen"
        :loading="isLoading"
        v-bind:class="{ grayOut: cartSize == 0, activeJump: cartSize > 0, 
                        scheduledOrdersOnlyEmptyCartGR: shouldShowOnlyScheduledOrders && language=='GRE' && cartSize == 0,
                        scheduledOrdersOnlyEmptyCartENG: shouldShowOnlyScheduledOrders && language=='ENG' && cartSize == 0,
                        scheduledOrdersOnlyFullCartGR: shouldShowOnlyScheduledOrders && language=='GRE' && cartSize > 0,
                        scheduledOrdersOnlyFullCartENG: shouldShowOnlyScheduledOrders && language=='ENG' && cartSize > 0  }"
        id="mobile-send-order-button"
      >
        <div id="mobile-cart-button-content">
          <div :class="{ notRotate: reverseRotate, goRight: reverseRotate }" id="cart-size">
            <h6>{{ cartSize }}</h6>
          </div>
          <div id="mobile-button-text" :class="{ notRotate: reverseRotate}">
            <h5>{{ mobileButtonText }}</h5>
          </div>
          <div
            :class="{
              goLeft: reverseRotate
            }"
            id="mobile-cost"
            v-if="!shouldShowOnlyScheduledOrders"
          >
            <h6 :class="{ notRotate: reverseRotate }">{{ totalCost}}€</h6>
          </div>
        </div>
      </Button>
    </div>
    <div class="custom-footer-message">
      <CustomBottomBanner
        v-if="store && menu.restaurant.names.translation[language].customFooterBannerText!=null && menu.restaurant.names.translation[language].customFooterBannerText!=''"
        :restaurantNames="menu.restaurant.names"
      ></CustomBottomBanner>
    </div>
    <Footer v-if="store && store.showFluterFooter"></Footer>
    <PoweredByFooter v-else-if="menu && menu.restaurant" :menuType="menu.restaurant.type"></PoweredByFooter>
    <CategoriesModal
      :categories="categoriesToShow"
      :isPda="isPda || isPdaAndHasID"
      v-if="isCategoriesModalOpen"
    ></CategoriesModal>
  </div>
</template>

<script>
import CategoryProducts from "../CategoryProducts";
import CategoriesModal from "../CategoriesModal";
import Store from "../Store";
import Categories from "../Categories";
import Cart from "../Cart";
import CartItem from "../CartItem";
import MenuApi from "../../api/menu";
import StoreApi from "../../api/stores";
import UserApi from "../../api/user";
import ProfileDropdown from "../ProfileDropdown";
import EnterPrompt from "../EnterPrompt";
import LanguageSelect from "../LanguageSelect";
import Button from "../Button";
import Ribbon from "../Ribbon";
import ContestRibbon from "../ContestRibbon";
import ContestModal from "../ContestModal";
import BackToServices from "../BackToServices.vue";
import CategoriesNavbar from "../CategoriesNavbar.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

import { getCurrentGreekDate } from "../../assets/js/utils.js";
import FluterDiscountBanner from "../FluterDiscountBanner.vue";
import CustomHeaderBanner from "../CustomHeaderBanner.vue";
import CustomBottomBanner from "../CustomBottomBanner.vue";
import Footer from "../Footer.vue";
import PoweredByFooter from "../PoweredByFooter.vue";

export default {
  name: "MenuScreen",
  components: {
    CategoryProducts,
    CategoriesModal,
    Categories,
    Cart,
    CartItem,
    Store,
    ProfileDropdown,
    EnterPrompt,
    Loading,
    LanguageSelect,
    Button,
    Ribbon,
    ContestRibbon,
    ContestModal,
    BackToServices,
    CategoriesNavbar,
    FluterDiscountBanner,
    CustomHeaderBanner,
    CustomBottomBanner,
    Footer,
    PoweredByFooter
  },
  created() {
    if (this.cartSize > 0) {
      this.reverseRotate = true;
    }
    let routeName = this.$router.currentRoute.name;
    if (routeName === "menu-with-categories") {
      this.$store.dispatch("setRollbackCategoriesState", this.categories);
      this.$store.dispatch("mobileCategoriesHaveOpened");
      this.$store.dispatch("openMobileCategories");
    } else if (routeName === "menu-with-happy-hour") {
      this.$router.push({ name: "menu" });
    }
  },
  async mounted() {
    this.isLoading = true;
    window.scrollTo(0, 0);
    let tableId = this.$router.currentRoute.params.tableId;
    let service = this.$router.currentRoute.params.service;
    let menu = undefined;

    if (this.$route.params.menu) {
      if (this.$route.params.menu.menus[0].restaurant.type == 'INFO' || ((this.$route.params.menu.menus[0].restaurant.type == 'MENU' || this.$route.params.menu.menus[0].restaurant.type == 'REQUESTS') && this.$route.params.menu.menus[0].categories.length > 0)) {
        menu = this.$route.params.menu;
      } else {
        let isPda = this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined;
        menu = await MenuApi.getMenuForServiceAndTable(service, tableId, isPda);
      }
    } else {
      let isPda = this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined;
      menu = await MenuApi.getMenuForServiceAndTable(service, tableId, isPda);
    }


    let redirectLinkBasedOnLanguage = menu.menus[0].restaurant.names.translation[this.language].redirectLink;
    if (redirectLinkBasedOnLanguage != null && redirectLinkBasedOnLanguage.trim().length > 0) {
      window.location.href = redirectLinkBasedOnLanguage;
    } else if (menu.menus[0].restaurant.redirectLink) {
      window.location.href = menu.menus[0].restaurant.redirectLink;
    } else {
      this.$store.dispatch("setSearchProductsInput", "");
      this.bannerImage = menu.menus[0].restaurant.bannerLink != null && menu.menus[0].restaurant.bannerLink.trim().length > 0 ? menu.menus[0].restaurant.bannerLink : menu.store.bannerLink;
      let currentGreekDate = await getCurrentGreekDate();
      this.$store.dispatch("setCurrentGreekDate", currentGreekDate);

      this.$store.dispatch("setStoreId", menu.store.id);
      this.$store.dispatch("setStoreIsHotel", menu.store.hotel);
      this.$store.dispatch("setNumberOfServices", menu.store.amountOfDepartments);
      this.$store.dispatch("setServiceId", menu.menus[0].restaurant.id);
      this.$store.dispatch("setServiceMembershipEnabled", menu.menus[0].restaurant.membershipEnabled);
      this.$store.dispatch("setServiceType", menu.menus[0].restaurant.type);
      this.$store.dispatch("setServiceMembershipPricesVisible", menu.menus[0].restaurant.membershipPricesVisible);
      this.$store.dispatch("setTableId", tableId);
      this.$store.dispatch("setServiceMaxItemsPerOrder", menu.menus[0].restaurant.maxItemsPerOrder);
      this.$store.dispatch("setTableSection", menu.menus[0].table.section);

      this.$store.dispatch("setIsInteractiveCatalogue", !menu.menus[0].table.hideInteractivity);
      this.service = service;
      this.$store.dispatch("setTableCapacity", menu.menus[0].table.capacity);
      this.$store.dispatch("setTableType", menu.menus[0].table.type);

      if ((menu.menus[0].table.type == "DELIVERY" || menu.menus[0].table.type == "DELIVERY_TABLE") && menu.menus[0].restaurant.deliveryDiscount != undefined) {
        this.$store.dispatch("doesHaveDeliveryDiscount");
        this.$store.dispatch("setDeliveryDiscountPercentage", menu.menus[0].restaurant.deliveryDiscount);
        this.$store.dispatch("setMinimumPriceForDeliveryDiscount", menu.menus[0].restaurant.minimumPriceForDeliveryDiscount);
        this.minimumPriceForDeliveryDiscount = menu.menus[0].restaurant.minimumPriceForDeliveryDiscount;
      } else {
        this.$store.dispatch("doesNotHaveDeliveryDiscount");
      }

      this.isAbleToChangeLanguage = menu.store.ableToChangeLanguage;
      if (menu.store.ableToChangeLanguage) {
        try {
          let navigatorLanguage = navigator.language;
          if (navigatorLanguage == 'el') {
            this.$store.dispatch("setDefaultLanguage", 'GRE');
          } else {
            this.$store.dispatch("setDefaultLanguage", menu.store.language);
          }
        } catch (error) {
          this.$store.dispatch("setDefaultLanguage", menu.store.language);
        }
      } else {
        this.$store.dispatch("setLanguageOnlyToMemory", menu.store.language);
      }

      this.$store.dispatch("setServiceStartTime", menu.menus[0].restaurant.startTime);
      this.$store.dispatch("setServiceEndTime", menu.menus[0].restaurant.endTime);

      this.$store.dispatch("setServiceScheduledOrdersEnabled", menu.menus[0].restaurant.scheduledOrdersEnabled);
      this.$store.dispatch("setServiceDelayedResponse", menu.menus[0].restaurant.delayedResponse);
      this.$store.dispatch("setServiceScheduledOrdersStartTime", menu.menus[0].restaurant.scheduledOrdersStartTime);
      this.$store.dispatch("setServiceScheduledOrdersEndTime", menu.menus[0].restaurant.scheduledOrdersEndTime);

      this.$store.dispatch("setServiceName", menu.menus[0].restaurant.names.translation);


      if (menu.menus[0].restaurant.membershipEnabled && this.isLoggedIn) {
        let userHasMembership = await UserApi.hasActiveMembership(menu.menus[0].restaurant.id);
        this.userHasMembership = userHasMembership;
        this.$store.dispatch("setUserHasMembershipForService", userHasMembership);
      }

      this.serviceStartTime = menu.menus[0].restaurant.startTime;
      this.serviceEndTime = menu.menus[0].restaurant.endTime;

      this.isScheduledOrdersEnabled = menu.menus[0].restaurant.scheduledOrdersEnabled;
      this.scheduledOrdersStartTime = menu.menus[0].restaurant.scheduledOrdersStartTime;
      this.scheduledOrdersEndTime = menu.menus[0].restaurant.scheduledOrdersEndTime;

      this.$store.dispatch("setIsReadOnlyEnabled", menu.menus[0].restaurant.readOnlyEnabled)

      menu.menus[0].categories.forEach((category) => {
        category.anchor = category.names.translation["ENG"].name.replace(" ", "-");
      });

      this.menu = menu.menus[0];
      this.isPda = this.menu.table.pda;

      if (!this.menu.table.hideInteractivity) {
        this.$store.dispatch("setAvailableTables", []);
        this.$store.dispatch("setTableFamilySections", []);
        this.$store.dispatch("setAvailableChargingRooms", []);

        if (this.menu.table.type == 'HOTEL_ROOM') {
          this.$store.dispatch("setSelectedTable", undefined);

          StoreApi.getListOfRooms(menu.store.id, menu.store.fetchRoomsPerSection, this.menu.table.section, true);

        } else if (this.menu.table.type != 'DELIVERY') {
          this.$store.dispatch("setSelectedTable", undefined);

          if (this.menu.table.pda && this.menu.table.fetchAllTableTypes) {
            this.menu.table.type == 'QR_SELF_SERVICE' ? StoreApi.getAllAvailableTablesAndPackagesOfStore(menu.store.id) : StoreApi.getAllAvailableTablesForRestaurant(menu.store.id, this.menu.restaurant.id);
            StoreApi.getTableSections(menu.store.id, true);
          } else {
            StoreApi.getListOfAvailableTables(
              menu.store.id,
              menu.store.fetchTablesPerSection,
              this.menu.restaurant.id,
              this.menu.table.type,
              this.menu.table.section
            );
          }

          if (this.menu.table.type == 'TABLE_AT_HOTEL' || this.menu.table.type == 'UMBRELLA_AT_HOTEL') {
            this.$store.dispatch("setSelectedChargingRoom", undefined);
            StoreApi.getChargingRooms(menu.store.id);
          }
        }
      }

      this.deliveryType = this.menu.table.type == 'HOTEL_ROOM' ? this.menu.restaurant.roomDeliveryType : this.menu.restaurant.deliveryType;
      this.isApplication = this.menu.table.application;

      this.isRoomServiceCharged = menu.menus[0].restaurant.roomServiceCharged;
      this.typeOfRoomServiceCharge = menu.menus[0].restaurant.typeOfRoomServiceCharge;
      this.roomServiceCharge = menu.menus[0].restaurant.roomServiceCharge;

      this.showContestRibbon = menu.menus[0].restaurant.participatesInContest;
      if (this.fromRouteName != 'cart' && menu.menus[0].restaurant.participatesInContest) {
        this.showContestModal();
      }

      this.filterOutInvalidCartItems(menu.menus[0], menu.store.id);

      this.lastScroll = window.pageYOffset || document.documentElement.scrollTop;
      window.addEventListener("scroll", this.highlightCategory);

      let increaseFromService = false;
      if (this.from && this.from.name) {
        increaseFromService =
          this.from.name.includes("services") &&
          this.$router.currentRoute.params.increaseCounter;
      }

      if (
        !this.from ||
        !this.from.name ||
        (increaseFromService && !this.from.name.includes("cart"))
      ) {
        MenuApi.increaseMenuScans(menu.menus[0].restaurant.id);
      }

      this.store = menu.store;

      if (this.tableHasRedirectBackLink) {
        this.redirectBackTo = this.menu.table.redirectBackTo;
        let backServiceName = {
          GRE: {
            name: menu.menus[0].table.serviceName
          },
          ENG: {
            name: menu.menus[0].table.serviceName
          },
        };
        this.$store.dispatch("setServiceName", backServiceName);
      } else if (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
        this.redirectBackTo = this.$route.query.pdaId;
      }

      if (this.isLoggedIn && (this.isPda || (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined))) {
        let restaurantIds = [menu.menus[0].restaurant.id];
        UserApi.getAdminAccounts(restaurantIds, this.authToken, this.jwt)
      }

      if (menu.menus[0].restaurant.customItems && menu.menus[0].restaurant.customItems.EXTRA_LANGUAGE != null && menu.menus[0].restaurant.customItems.EXTRA_LANGUAGE.trim().length != 0) {
        let extraLanguages = menu.menus[0].restaurant.customItems.EXTRA_LANGUAGE.split(",");
        this.$store.dispatch("setExtraLanguages", extraLanguages);
      }

      // we show enterprompt only for non read_only stores
      // in order to avoid unecassary logins and not to get charged
      this.shouldShowEnterPrompt = this.store.pricingPackage != "READ_ONLY";

      this.isLoading = false;
      setTimeout(() => {
        this.scrollDownPixels(1);
      }, 130);
      this.setTitle();
    }

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.highlightCategory);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRouteName = from.name;
      vm.from = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isModalOpen) {
      this.$store.dispatch("closeItemModal");
      this.$store.dispatch("setItemModalQuantity", 1);
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed for same reason as above
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.areMobileCategoriesOpen) {
      this.$store.dispatch("closeMobileCategories");
      this.$router.push({
        name: "menu",
      });
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isContestModalOpen) {
      this.$store.dispatch("closeContestModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isCategoriesModalOpen) {
      this.$store.dispatch("closeCategoriesModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      this.$store.dispatch("itemHasNotBeenInserted");
      next();
    }
  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    storeId() {
      return this.$store.getters.getStoreId;
    },

    categories() {
      return this.$store.getters.getCategories;
    },

    searchProductsInput() {
      return this.$store.getters.getSearchProductsInput;
    },

    cartSize() {
      return this.$store.getters.getCartSize;
    },

    isModalOpen() {
      return this.$store.state.itemModal.isOpen;
    },

    isCartPreviewOpen() {
      return this.$store.state.cartPreview.isCartPreviewOpen;
    },

    hasCartPreviewOpened() {
      return this.$store.state.cartPreview.hasCartPreviewOpened;
    },

    hasItemBeenInserted() {
      return this.$store.getters.getHasItemBeenInserted;
    },

    cartItems() {
      return this.$store.getters.getCartItems;
    },

    totalCost() {
      return this.$store.getters.getTotalCost.toFixed(2);
    },

    areMobileCategoriesOpen() {
      return this.$store.getters.getAreMobileCategoriesOpen;
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    isInteractiveCatalogue() {
      return this.$store.getters.getIsInteractiveCatalogue;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    isCategoriesModalOpen() {
      return this.$store.state.categoriesModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },

    isContestModalOpen() {
      return this.$store.getters.isContestModalOpen;
    },

    isDelivery() {
      return this.$store.getters.getTableType == "DELIVERY" || this.$store.getters.getTableType == "DELIVERY_TABLE";
    },

    isHotelRoomOrDelivery() {
      return this.$store.getters.getTableType == "HOTEL_ROOM" || this.isDelivery || this.isDeliveryTable;
    },

    isQrSelfService() {
      return this.tableType == "QR_SELF_SERVICE";
    },

    isSittingTable() {
      return this.tableType == 'TABLE' || this.tableType == 'BEACH_CHAIR' || this.tableType == 'TABLE_AT_HOTEL' || this.tableType == 'UMBRELLA_AT_HOTEL';
    },

    tableType() {
      return this.$store.getters.getTableType
    },

    deliveryDiscountPercentage() {
      return this.$store.getters.getDeliveryDiscountPercentage;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate;
    },

    categoriesToShow() {
      if (this.menu == [] || this.menu == null || this.menu.length == 0) {
        let categories = [];
        this.$store.dispatch("setCategories", []);
        return categories;
      } else {
        if (this.searchProductsInput == "") {
          let categoriesWithoutProducts = [];
          for (let category of this.menu.categories) {
            categoriesWithoutProducts.push({
              categoryId: category.categoryId,
              hiddenWhenOutOfTime: category.hiddenWhenOutOfTime,
              maxProductsPerCartForThisCategory: category.maxProductsPerCartForThisCategory,
              name: category.name,
              schedules: category.schedules,
              separator: category.separator,
              viewPriority: category.viewPriority,
              id: category.categoryId,
              names: category.names,
              startTime: category.startTime ? category.startTime : undefined,
              endTime: category.endTime ? category.endTime : undefined,
              anchor: category.names.translation["ENG"].name.replace(" ", "-"),
              isActive: false,
            });
          }
          this.$store.dispatch("setCategories", categoriesWithoutProducts);
          return this.menu.categories;
        } else {
          let filteredCategoriesWithProducts = [];
          let filteredCategoriesWithoutProducts = [];
          for (let category of this.menu.categories) {
            let filteredProducts = [];
            if (this.language == 'GRE') {
              filteredProducts = category.products.filter(product => {
                let productNameWithoutAccents = product.names.translation[this.language].name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                let searchInputWithoutAccents = this.searchProductsInput.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                if (productNameWithoutAccents.toUpperCase().includes(searchInputWithoutAccents.toUpperCase())) {
                  return true;
                } else {
                  return false;
                }
              })
            } else {
              filteredProducts = category.products.filter(product => product.names.translation[this.language].name.toUpperCase().includes(this.searchProductsInput.toUpperCase()));
            }

            if (filteredProducts.length > 0) {
              filteredCategoriesWithProducts.push({
                categoryId: category.categoryId,
                hiddenWhenOutOfTime: category.hiddenWhenOutOfTime,
                maxProductsPerCartForThisCategory: category.maxProductsPerCartForThisCategory,
                name: category.name,
                products: filteredProducts,
                schedules: category.schedules,
                separator: category.separator,
                viewPriority: category.viewPriority,
                id: category.categoryId,
                names: category.names,
                startTime: category.startTime ? category.startTime : undefined,
                endTime: category.endTime ? category.endTime : undefined,
                anchor: category.names.translation["ENG"].name.replace(" ", "-"),
                isActive: false,
              });

              filteredCategoriesWithoutProducts.push({
                categoryId: category.categoryId,
                hiddenWhenOutOfTime: category.hiddenWhenOutOfTime,
                maxProductsPerCartForThisCategory: category.maxProductsPerCartForThisCategory,
                name: category.name,
                schedules: category.schedules,
                separator: category.separator,
                viewPriority: category.viewPriority,
                id: category.categoryId,
                names: category.names,
                startTime: category.startTime ? category.startTime : undefined,
                endTime: category.endTime ? category.endTime : undefined,
                anchor: category.names.translation["ENG"].name.replace(" ", "-"),
                isActive: false,
              });
            }
          }

          this.$store.dispatch("setCategories", filteredCategoriesWithoutProducts);
          if (filteredCategoriesWithProducts.length > 0) {
            this.scrollToCategory(filteredCategoriesWithProducts[0].anchor)
          }
          return filteredCategoriesWithProducts;
        }

      }
    },

    showFluterBanner() {
      return this.store &&
        (this.menu.restaurant.readOnlyEnabled ||
          !this.isServiceOpen ||
          (this.isServiceOpen && this.deliveryType == 'SELF_SERVICE') ||
          (this.isServiceOpen && this.deliveryType == 'DINE_IN') ||
          (this.menu.table.type == 'HOTEL_ROOM' && this.deliveryType == 'DELIVERY' && this.isRoomServiceCharged && this.roomServiceCharge > 0 && (this.typeOfRoomServiceCharge == 'FIXED_FOR_CATALOGUE' || this.typeOfRoomServiceCharge == 'PERCENTAGE_CHARGE')) ||
          ((this.menu.table.type == 'DELIVERY' || this.menu.table.type == 'DELIVERY_TABLE') && this.menu.restaurant.deliveryDiscount > 0) ||
          this.store.pricingPackage == 'READ_ONLY') &&
        this.menu.restaurant &&
        !(this.shouldShowOnlyScheduledOrders && this.menu.restaurant.delayedResponse) &&
        (this.menu.restaurant.type == 'MENU' || this.menu.restaurant.type == 'REQUESTS') && this.isInteractiveCatalogue
    },

    isServiceOpen() {
      if (
        !this.serviceStartTime ||
        !this.serviceEndTime ||
        this.currentGreekDate == "" ||
        this.currentGreekDate == undefined
      ) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isServiceOpen = true;

      if (this.serviceStartTime < this.serviceEndTime) {
        isServiceOpen =
          this.serviceStartTime < currentTime &&
          this.serviceEndTime > currentTime;
      } else {
        isServiceOpen =
          this.serviceStartTime < currentTime ||
          this.serviceEndTime > currentTime;
      }

      return isServiceOpen;
    },

    isReadOnlyEnabled() {
      return this.$store.getters.getIsReadOnlyEnabled;
    },

    isShedulingHoursOrdersOpen() {
      if (
        !this.scheduledOrdersStartTime ||
        !this.scheduledOrdersEndTime ||
        this.currentGreekDate == "" ||
        this.currentGreekDate == undefined
      ) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isShedulingHoursOrdersOpen = true;

      if (this.scheduledOrdersStartTime < this.scheduledOrdersEndTime) {
        isShedulingHoursOrdersOpen =
          this.scheduledOrdersStartTime < currentTime &&
          this.scheduledOrdersEndTime > currentTime;
      } else {
        isShedulingHoursOrdersOpen =
          this.scheduledOrdersStartTime < currentTime ||
          this.scheduledOrdersEndTime > currentTime;
      }

      return isShedulingHoursOrdersOpen;
    },

    isOpenForScheduledOrders() {
      return this.isScheduledOrdersEnabled && this.isShedulingHoursOrdersOpen;
    },

    isOpenForScheduledButNotInCurrentTime() {
      return this.isScheduledOrdersEnabled && !this.isShedulingHoursOrdersOpen;
    },

    shouldShowOnlyScheduledOrders() {
      return ((this.isHotelRoomOrDelivery || this.isQrSelfService || (this.isSittingTable && this.menu.restaurant.delayedResponse)) && this.isOpenForScheduledOrders && (!this.isServiceOpen || (this.isServiceOpen && this.isReadOnlyEnabled)))
    },

    mobileButtonText() {
      if (this.shouldShowOnlyScheduledOrders) {
        return this.menu.restaurant.delayedResponse ? `${this.labels.cart.myRequests[this.language]}` : `${this.labels.cart.scheduleOrder[this.language]}`
      } else {
        return this.menu.restaurant.delayedResponse ? `${this.labels.cart.myRequests[this.language]}` : `${this.labels.cart.myCart[this.language]}`
      }
    },

    storeName() {
      if (this.store) {
        return this.store.names && this.store.names.translation[this.language]
          ? this.store.names.translation[this.language].name
          : this.store.name;
      }
    },

    tableHasRedirectBackLink() {
      return this.menu && this.menu.table && this.menu.table.redirectBackTo != null && this.menu.table.serviceName != null && this.menu.table.serviceName.trim().length > 0
    },

    isPdaAndHasID() {
      return this.menu && this.menu.table && this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined;
    },

    title() {
      if (this.store) {
        return `${this.labels.titles.menu[this.language]} - ${this.storeName
          } | Fluter`;
      } else {
        return `${this.labels.titles.menu[this.language]} | Fluter`;
      }
    },
  },

  watch: {
    cartSize: function (newCartSize, oldCartSize) {
      if (oldCartSize == 0 && newCartSize >= 1) {
        setTimeout(() => {
          this.reverseRotate = true;
        }, 300);
      } else if (newCartSize == 0) {
        this.reverseRotate = false;
      }
    },
    isModalOpen: function (newVal, oldVal) {
      let htmlElement = document.querySelector("html");
      if (newVal == true) {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "auto";
      }
    },
    isCategoriesModalOpen: function (newVal, oldVal) {
      let htmlElement = document.querySelector("html");
      if (newVal == true) {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "auto";
      }
    },

  },
  props: ["isViewOnly"],
  data() {
    return {
      menu: [],
      store: undefined,
      isFirstCategoriesUpdate: true,
      showContestRibbon: false,
      isLoading: true,
      serviceStartTime: undefined,
      serviceEndTime: undefined,
      isScheduledOrdersEnabled: undefined,
      scheduledOrdersStartTime: undefined,
      scheduledOrdersEndTime: undefined,
      userHasMembership: false,
      reverseRotate: false,
      from: undefined,
      lastScroll: 0,
      lastIndex: 0,
      fromRouteName: '',
      shouldShowEnterPrompt: false,
      deliveryType: undefined,
      isApplication: false,
      minimumPriceForDeliveryDiscount: 0,
      isAbleToChangeLanguage: true,
      isRoomServiceCharged: false,
      typeOfRoomServiceCharge: "",
      roomServiceCharge: 0,
      bannerImage: null,
      isPda: false,
      redirectBackTo: null
    };
  },

  methods: {

    getCategoryName(category) {
      if (this.selectedExtraLanguage != null && category.names.translation[this.selectedExtraLanguage] && category.names.translation[this.selectedExtraLanguage].name != null && category.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return category.names.translation[this.selectedExtraLanguage].name;
      }
      return category.names.translation[this.language].name;
    },

    getCategoryDescription(category) {
      if (this.selectedExtraLanguage != null && category.names.translation[this.selectedExtraLanguage] && category.names.translation[this.selectedExtraLanguage].description != null && category.names.translation[this.selectedExtraLanguage].description.trim().length != 0) {
        return category.names.translation[this.selectedExtraLanguage].description;
      }
      return category.names.translation[this.language].description;
    },

    scrollToCategory(anchor) {
      setTimeout(() => {
        let yOffset = -window.screen.height * 0.08 - 49;

        const y =
          document.getElementById(anchor).getBoundingClientRect()
            .top +
          window.pageYOffset +
          yOffset;

        window.scrollTo({ top: y });

        let el = document.getElementById(`${anchor}-id`);
        const elLeft = el.offsetLeft + el.offsetWidth;
        const elRight = el.offsetLeft;
        const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

        // check if element not in view
        if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
          el.parentNode.scrollLeft =
            elRight - el.parentNode.offsetWidth / 2 - el.offsetWidth / 2;
        } else if (
          elLeft <=
          el.parentNode.offsetLeft + el.parentNode.scrollLeft
        ) {
          el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
        } else if (elRight < el.parentNode.scrollLeft) {
          el.parentNode.scrollLeft =
            elRight - el.parentNode.offsetWidth / 2 + el.offsetWidth;
        }
      }, 65);
    },

    highlightCategory: function () {
      if (this.menu.restaurant.type == "INFO") {
        return;
      }
      let newCategories = this.categories;
      newCategories.forEach((category, index) => {
        let element = document.getElementById(`${category.anchor}`);
        let horizontalCategory = document.getElementById(
          `${category.anchor}-id`
        );
        var rectangle = element.getBoundingClientRect();

        let isActive = false;
        let topOffset =
          this.store.amountOfDepartments > 1
            ? this.$screen.height * 0.2 + 49
            : this.$screen.height * 0.2;

        isActive =
          this.store.amountOfDepartments > 1
            ? rectangle.top <= 0 + topOffset &&
            rectangle.bottom > 0 + this.$screen.height * 0.185 + 49
            : rectangle.top <= 0 + topOffset &&
            rectangle.bottom > 0 + this.$screen.height * 0.185;

        if (isActive) {
          category.isActive = true;
          this.lastIndex = index;
          newCategories.forEach((newCategory, newIndex) => {
            if (newIndex != this.lastIndex) newCategory.isActive = false;
          });

          if (this.$screen.width <= 1285) {
            const elLeft =
              horizontalCategory.offsetLeft + horizontalCategory.offsetWidth;
            const elRight = horizontalCategory.offsetLeft;
            const elParentLeft =
              horizontalCategory.parentNode.offsetLeft +
              horizontalCategory.parentNode.offsetWidth;

            // check if element not in view
            if (
              elLeft >=
              elParentLeft + horizontalCategory.parentNode.scrollLeft
            ) {
              horizontalCategory.parentNode.scrollLeft =
                elRight - window.screen.width / 2;
            } else if (
              elLeft <=
              horizontalCategory.parentNode.offsetLeft +
              horizontalCategory.parentNode.scrollLeft
            ) {
              horizontalCategory.parentNode.scrollLeft =
                horizontalCategory.offsetLeft -
                horizontalCategory.parentNode.offsetLeft;
            } else if (elRight <= horizontalCategory.parentNode.scrollLeft) {
              horizontalCategory.parentNode.scrollLeft =
                elRight -
                window.screen.width / 2 +
                horizontalCategory.offsetWidth;
            }
          } else {
            let parentDiv = horizontalCategory.parentNode.parentNode;

            let parentTop = parentDiv.scrollTop;
            let parentBottom = parentTop + parentDiv.clientHeight;

            //Determine element top and bottom
            let categoryTop = horizontalCategory.offsetTop;
            let categoryBottom = categoryTop + horizontalCategory.clientHeight;

            //Check if out of view
            if (categoryTop < parentTop) {
              parentDiv.scrollTop -= parentTop - categoryTop;
            } else if (categoryBottom > parentBottom) {
              parentDiv.scrollTop += categoryBottom - parentBottom;
            }
          }
        } else {
          let categoriesFiltered = newCategories.filter(
            (newCategory) => newCategory.isActive
          );
          if (categoriesFiltered.length != 0) {
            category.isActive = false;
          } else {
            newCategories[this.lastIndex].isActive = true;
          }
        }
      });

      // this.$store.dispatch("setCategories", newCategories);
    },

    onCartClick: function () {
      if (this.isCartPreviewOpen) {
        this.$store.dispatch("closeCartPreview");
      } else {
        this.$store.dispatch("openCartPreview");
        if (!this.hasCartPreviewOpened) {
          this.$store.dispatch("cartPreviewHasOpened");
        }
      }
      this.showDetails = !this.showDetails;
      if (!this.hasOpened) {
        this.hasOpened = true;
      }
    },

    onMaskClick: function () {
      if (this.areMobileCategoriesOpen) {
        this.$store.dispatch("closeMobileCategories");
        this.$router.push({ name: "menu" });
      } else if (this.isEntranceOpen) {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      } else if (this.isContestModalOpen) {
        this.$store.dispatch("closeContestModal");
      } else if (this.isCategoriesModalOpen) {
        this.$store.dispatch("closeCategoriesModal");
      }
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    goToCartScreen: function () {
      if (this.cartSize != 0) {
        this.isLoading = true;
        setTimeout(() => {

          this.$store.dispatch("cartPreviewHasNotOpened");
          this.$store.dispatch("mobileCategoriesHaveNotOpened");
          let queryObject = null;
          if (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
            queryObject = { isPda: this.$route.query.isPda, pdaId: this.$route.query.pdaId, tableId: this.$route.query.tableId };
          }

          this.$router.push({
            name: "cart",
            params: {
              tableId: this.tableId,
              service: this.service,
              menu: this.menu,
              store: this.store
            },
            query: queryObject
          });
        }, 50);

      }
    },

    initiateEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    showContestModal: function () {
      this.$store.dispatch("openContestModal");
    },

    filterOutInvalidCartItems: function (menu, storeId) {
      let newCart = this.cartItems.filter(
        (cartItem) =>
          cartItem.storeId == storeId &&
          cartItem.serviceId == menu.restaurant.id
      );

      let newCartSubselections = _.map(newCart, (newCartItem) =>
        _.pick(newCartItem, "subselections")
      );

      let productCatecories = _.map(menu.categories, (category) =>
        _.pick(category, "products")
      );

      let allProducts = [];

      productCatecories.forEach((category) => {
        category.products.forEach((product) => {
          allProducts.push(product);
        });
      });

      let cartItemsToRemove = [];
      newCart.forEach((cartItem) => {
        let foundProduct = allProducts.find(
          (product) => product.id == cartItem.item.id
        );
        if (foundProduct) {
          // item is in!
          if (!foundProduct.available) {
            // "not available though...
            cartItemsToRemove.push(cartItem);
          }

          // test if matching subselections
          if (cartItem.item.subselections) {
            let cartItemSubselectionsIds = [];
            let cartItemOptions = [];
            let foundProductSubselectionsIds = [];
            let foundProductOptions = [];

            // testing not only if they have the same subselections, each subselection needs the same options
            // and each option needs to have the same price
            cartItem.item.subselections.forEach((subselection) => {
              cartItemSubselectionsIds.push(subselection.id);
              subselection.options.forEach((option) =>
                cartItemOptions.push({ id: option.id, price: option.price })
              );
            });

            foundProduct.subselections.forEach((subselection) => {
              foundProductSubselectionsIds.push(subselection.id);
              subselection.options.forEach((option) =>
                foundProductOptions.push({ id: option.id, price: option.price })
              );
            });

            let equalSubselections = _.isEqual(
              cartItemSubselectionsIds,
              foundProductSubselectionsIds
            );
            let equalOptions = _.isEqual(cartItemOptions, foundProductOptions);

            let haveSameSubselections = equalSubselections && equalOptions;

            if (!haveSameSubselections) {
              cartItemsToRemove.push(cartItem);
            }
          }
        } else {
          // item not here...
          cartItemsToRemove.push(cartItem);
        }
      });

      newCart = newCart.filter((item) => !cartItemsToRemove.includes(item));

      if (newCart !== this.cartItems) {
        this.$store.dispatch("setCart", newCart);
      }
    },

    formattedTime: function (time) {
      return time.slice(0, -3);
    },

    hasCategoryDescription: function (category) {
      return (
        category.names.translation[this.language].description &&
        category.names.translation[this.language].description.trim() != ""
      );
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    },

    scrollDownPixels(pixels) {
      try {
        window.scrollTo(0, window.scrollY + pixels);
      } catch (error) {
        console.log("Could not scroll");
      }
    },
  },
};
</script>

<style scoped>
#menu-screen {
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
  overflow-x: hidden;
  background: #fcfcfc;
  min-height: 100vh;
  z-index: 0;
  overflow-x: hidden !important;
}

#banner {
  width: 100vw;
  height: 55vh;
  min-height: 220px;
}

#banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#store-info {
  position: absolute;
  top: 15%;
  right: 5%;
  width: 30%;
  min-width: 400px;
  background: white;
  border-radius: 18px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.36), 2px 2px 4px rgba(0, 0, 0, 0.48);
}

#store {
  cursor: unset;
}

#body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

#food-items {
  margin-top: 3vh;
  margin-bottom: 5vh;
  width: 85vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu {
  width: 70%;
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

.minHeight {
  min-height: 600px;
}

#deals {
  margin-bottom: 10vh;
}

.hasBorderBottom {
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
}

.addMargin {
  margin-left: 4%;
  margin-right: 4%;
}

#categories {
  position: fixed;
  left: 5vw;
  top: 20vh;
  width: 20vw;
  z-index: 201;
}

.department-info-title {
  display: inline-flex;
  width: 92%;
}

#category-label-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  margin: 3% 4% 3% 4%;
  padding-bottom: 2%;
}

.category-description-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.category-description-icon {
  align-self: flex-start;
  margin-right: 3px;
  margin-top: 4px;
  font-size: 12px;
  color: rgb(121, 121, 121);
}

#category-description {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 600 !important;
  color: rgb(109, 109, 109);
}

.category-label {
  color: #1f1f1f;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 0;
}

.department-info-title {
  color: #003340;
  font-size: 20px;
  font-weight: 900;
  margin: 3% 4% 3% 4%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  padding-bottom: 2%;
}

#category-schedule {
  margin-top: 2px;
  font-size: 14px;
  align-self: center;
}

#upper-bar {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;

  padding-right: 1%;
}

#profile-and-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

#language-select {
  position: absolute;
  right: 200px;
  top: 8px;
  cursor: pointer;
}

.language-position-in-login {
  right: 159px !important;
  z-index: 202;
}

#enter-prompt {
  position: absolute;
  top: 8px;
  right: 88px !important;
  z-index: 201;
}

#profile-dropdown {
  position: absolute;
  top: 0px;
  right: 88px !important;
  z-index: 201;
}

#back-to-services {
  position: fixed;
  left: 80px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.32), 3px 4px 8px rgba(0, 0, 0, 0.46);
}

#logo-container {
  background: white !important;
  /* height: 45px; */
  margin-left: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  padding: 0px 16px 0px 12px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#logo {
  cursor: pointer;
  height: 40px;
  width: auto;
}

#link-to-landing {
  text-decoration: none;
}

#cart-background {
  background: white;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  min-height: 32px;
  min-width: 32px;
  margin-left: 2%;
  position: fixed;
  right: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 202;
}

.crossedOutBlack {
  text-decoration: line-through #333;
  margin-right: 6px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 204;
  opacity: 1;
  transition: 0.67s;
}

.hide {
  visibility: hidden;
}

.custom-footer-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#current-order {
  position: fixed;
  top: 11vh;
  right: 1vw;
  width: 20vw;
  min-width: 185px;
  min-height: 125px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  background: white;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  clip-path: circle(0% at 100% 0%);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  z-index: 2;
}

#current-order::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.openCartDetails {
  animation: showCartDetails 0.67s forwards ease-in-out;
}

.closeCartDetails {
  animation: hideCartDetails 0.67s forwards ease-in-out;
}

.showMobileCart {
  animation: showMobileCart 0.3s forwards ease-in-out;
  transform-origin: bottom;
}

.hideMobileCart {
  animation: hideMobileCart 0.3s forwards ease-in-out;
  transform-origin: bottom;
}

@keyframes showCartDetails {
  from {
    clip-path: circle(0% at 100% 0%);
  }

  to {
    clip-path: circle(75%);
  }
}

@keyframes hideCartDetails {
  from {
    clip-path: circle(75%);
  }

  to {
    clip-path: circle(0% at 100% 0%);
  }
}

@keyframes showMobileCart {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes hideMobileCart {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

#to-kalathi-mou {
  margin: 2vh 0 2vh 0;
  color: #003340;
  font-size: 20px;
  font-weight: bold;
}

#send-order-button {
  width: 90%;
  height: 70%;
  border: none;
  background: linear-gradient(62deg, #198b4a, #04d36f);
  /* background: linear-gradient(62deg, #141414, #322f2f); */
  color: white;
  font-size: 12px;
  font-weight: 600;
}

#send-order {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  height: 8vh;
  min-height: 60px;
  max-height: 120px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
}

#total-cost {
  width: 100%;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ed5005;
}

#total-cost > h6 {
  font-size: 16px !important;
  font-weight: 600;
}

#mobile-cart {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 9vh;
  min-height: 62px;
  max-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: white;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.16), 0 -2px 4px rgba(0, 0, 0, 0.23);
  transform: translateY(0%);
  z-index: 2;
}

#mobile-send-order-button {
  width: 90%;
  height: 70%;
  border: none;
  color: white;
  /* background: linear-gradient(62deg, #141414, #322f2f); */
  background: linear-gradient(62deg, #198b4a, #04d36f);
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.25))
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.14))
    drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.24));
}

.grayOut {
  opacity: 0.65;
}

.activeJump {
  animation: activeJump 0.67s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

@keyframes activeJump {
  from {
    transform: rotateY(0) scale(1);
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.25))
      drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.14))
      drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.24));
    /* background: linear-gradient(62deg, #141414, #322f2f); */
    background: linear-gradient(62deg, #198b4a, #04d36f);
  }

  67% {
    transform: rotateY(180deg) scale(1.25);
    filter: drop-shadow(-3px 3px 3px rgba(0, 0, 0, 0.8))
      drop-shadow(-2px 2px 6px rgba(0, 0, 0, 0.42))
      drop-shadow(-5px 5px 10px rgba(0, 0, 0, 0.72));
    /* background: linear-gradient(62deg, #322f2f, #141414); */
    background: linear-gradient(62deg, #04d36f, #198b4a);
  }

  to {
    transform: rotateY(180deg) scale(1);
    filter: drop-shadow(-3px 3px 1px rgba(0, 0, 0, 0.25))
      drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.14))
      drop-shadow(-5px 5px 5px rgba(0, 0, 0, 0.24));
    /* background: linear-gradient(62deg, #3b3838, #141414); */
    background: linear-gradient(62deg, #04d36f, #198b4a);
  }
}

.notRotate {
  transform: rotateY(-180deg) !important;
}

@keyframes noRotate {
  from {
    transform: rotateX(0) !important;
  }

  to {
    transform: rotateX(-30deg) !important;
  }
}

.goRight {
  left: unset !important;
  right: 10vw !important;
}

.goLeft {
  right: unset !important;
  left: 10vw !important;
}

#mobile-cart-button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#mobile-button-text > h5 {
  padding: 0;
  margin: 0;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0.4px;
}

#cart-size {
  position: absolute;
  left: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 20%;
  width: 3.5vh;
  height: 3.5vh;
  min-height: 20px;
  min-width: 20px;
  max-height: 53px;
  max-width: 53px;
  padding: 0 2vw 0 2vw;

  color: black;
}

#cart-size > h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

#mobile-cost {
  position: absolute;
  right: 10vw;
}

#mobile-cost > h6 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

#loading-mask {
  position: fixed;
  top: 0;
}

.message-container {
  /* width: 92%; */
  margin: 0% 0.5%;
}

.message-container ::v-deep .ql-align-center {
  display: flex !important;
  align-self: center !important;
}
.message-container ::v-deep .ql-align-right {
  display: flex !important;
  align-self: flex-end !important;
}
.message-container ::v-deep .ql-align-justify {
  text-align: justify;
  text-justify: inter-word;
}
.message-container ::v-deep li {
  display: list-item;
  list-style-position: inside;
  width: fit-content;
}
.message-container ::v-deep p {
  margin-bottom: 0px;
}
.message-container ::v-deep li.ql-align-center {
  display: list-item !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.message-container ::v-deep li.ql-align-right {
  display: list-item !important;
  margin-left: auto !important;
}

@media screen and (max-width: 1285px) {
  #language-select {
    position: absolute;
    left: unset;
    top: 5px !important;
    right: 20px;
    cursor: pointer;
  }

  #category-description {
    margin-top: 2px;
    font-size: 13px;
  }

  #category-label-container {
    margin: 3% 16px 3% 16px;
  }

  .language-position-in-login {
    right: 20px !important;
    z-index: 202;
  }

  #profile-dropdown {
    right: 20px !important;
  }

  .profile-dropdown-with-lang-select {
    top: 58px !important;
  }

  .profile-dropdown-without-lang-select {
    top: 2px !important;
  }

  #enter-prompt {
    position: absolute;
    right: 20px !important;
    margin: 0;
  }

  .enter-prompt-with-lang-select {
    top: 56px !important;
  }

  .enter-prompt-without-lang-select {
    top: 5px !important;
  }

  #back-to-services {
    left: 20px;
  }

  #logo-container {
    min-width: 95px !important;
    justify-content: flex-start;
    margin-left: 20px;
  }

  #logo {
    height: 33px;
  }

  .message-container {
    width: 92%;
    margin: 3% 4% 3% 4%;
  }

  #banner {
    height: 40vh;
  }

  #store-info {
    top: 165px;
    width: 90%;
    left: 5%;
    min-width: 200px;
    max-height: 112px;
  }

  #food-items {
    width: 100vw;
    margin-bottom: 9vh;
  }

  .menu {
    width: 100%;
    box-shadow: none;
  }

  #deals {
    margin-bottom: 5vh;
  }

  #upper-bar {
    top: 15px;
    height: unset;
  }

  .category-label {
    width: 98%;
    font-size: 26px;
  }

  .department-info-title {
    width: 86%;
    font-size: 16px;
    margin: 3% 7% 3% 7%;
  }

  #category-schedule {
    font-size: 12px;
  }

  .scheduledOrdersOnlyEmptyCartGR {
    padding-left: 55px !important;
  }

  .scheduledOrdersOnlyEmptyCartENG {
    padding-left: 37px !important;
  }

  .scheduledOrdersOnlyFullCartGR {
    padding-right: 55px !important;
  }

  .scheduledOrdersOnlyFullCartENG {
    padding-right: 37px !important;
  }
}

@media screen and (max-width: 350px) {
  #store-info {
    top: 140px;
  }
}
@media screen and (max-width: 753px) {
  .category-label {
    font-size: 21px;
  }
}

@media screen and (max-width: 411px) {
  .scheduledOrdersOnlyEmptyCartGR {
    padding-left: 62px !important;
  }

  .scheduledOrdersOnlyEmptyCartENG {
    padding-left: 50px !important;
  }

  .scheduledOrdersOnlyFullCartGR {
    padding-right: 62px !important;
  }

  .scheduledOrdersOnlyFullCartENG {
    padding-right: 50px !important;
  }
}

@media screen and (max-width: 310px) {
  .scheduledOrdersOnlyEmptyCartGR {
    padding-left: 52px !important;
  }

  .scheduledOrdersOnlyFullCartGR {
    padding-right: 60px !important;
  }
}

@media screen and (max-width: 307px) {
  .scheduledOrdersOnlyFullCartGR {
    padding-right: 59px !important;
  }
}

@media (max-height: 250px) {
  #categories {
    top: 100px;
  }
}

@media (max-width: 350px) {
  #mobile-button-text > h5 {
    font-size: 12px !important;
  }

  #mobile-cost > h6 {
    font-size: 12px;
  }

  #cart-size > h6 {
    font-size: 11px;
  }
}

@media screen and (max-width: 1285px) {
  #banner {
    height: 290px !important;
  }
}

@media screen and (max-width: 530px) {
  #banner {
    height: 280px !important;
  }
}

@media screen and (max-width: 350px) {
  #banner {
    height: 250px !important;
  }
}

@media screen and (max-width: 330px) {
  #banner {
    height: 235px !important;
  }
}
</style>
