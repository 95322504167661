<template>
  <div
    translate="no"
    @click="onClickOutside"
    id="categories-modal-container"
    class="categories-modal-container-top-screen"
  >
    <div id="categories-modal" class="categories-modal-bigger">
      <div id="categories-header-and-content-container">
        <div id="categories-modal-header">
          <div id="categories-title-and-close-button">
            <h3 id="categories-title-name">{{ labels.categories.categoriesHeader[language] }}</h3>
            <div @click="onCloseClick" id="close-categories-modal">
              <font-awesome-icon icon="times" />
            </div>
          </div>
        </div>

        <div id="categories-modal-content-container">
          <div id="categories-of-menu-container" v-if="!isPda">
            <div
              class="category-of-menu"
              @click="scrollToCategory(category.anchor)"
              v-for="category in categories"
              :key="category.categoryId"
            >
              <h6 class="category-name">{{ getCategoryName(category) }}</h6>
            </div>
          </div>

          <div v-else id="categories-of-menu-container">
            <div
              class="pda-category-of-menu"
              :class="{ 'separator-category': pdaCategory.firstCategory.separator  || (pdaCategory.secondCategory && pdaCategory.secondCategory.separator)}"
              v-for="(pdaCategory, index) in pdaCategories"
              :key="index"
            >
              <h6
                class="pda-category-name"
                :class="{ 'width-100': pdaCategory.firstCategory.separator }"
                style="margin-right:4px;"
                @click="scrollToCategory(pdaCategory.firstCategory.anchor)"
              >{{ pdaCategory.firstCategory.names.translation[language].name }}</h6>
              <h6
                v-if="pdaCategory.secondCategory"
                class="pda-category-name"
                style="margin-left:4px;"
                @click="scrollToCategory(pdaCategory.secondCategory.anchor)"
              >{{ pdaCategory.secondCategory.names.translation[language].name }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CategoriesModal",
  components: {
  },
  props: ["categories", "isPda"],
  data() {
    return {
      hideDetails: true,
    };
  },
  watch: {
  },
  computed: {

    language() {
      return this.$store.getters.getLanguage;
    },

    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },

    pdaCategories() {
      let pdaCategories = [];
      for (let i = 0; i < this.categories.length; i += 2) {
        let firstCategory = this.categories[i];
        let secondCategory = i + 1 >= this.categories.length ? null : this.categories[i + 1];
        if (secondCategory != null) {
          if (!firstCategory.separator && !secondCategory.separator) {
            let categoryWithNeighbour = {
              id: this.categories[i].categoryId,
              firstCategory: firstCategory,
              secondCategory: secondCategory
            }
            pdaCategories.push(categoryWithNeighbour);
          } else if (firstCategory.separator && !secondCategory.separator) {
            let categoryWithNeighbour = {
              id: this.categories[i].categoryId,
              firstCategory: firstCategory,
              secondCategory: null
            }
            pdaCategories.push(categoryWithNeighbour);
            i = i - 1;
          } else if (!firstCategory.separator && secondCategory.separator) {
            let categoryWithNeighbour = {
              id: this.categories[i].categoryId,
              firstCategory: firstCategory,
              secondCategory: secondCategory
            }
            pdaCategories.push(categoryWithNeighbour);
          } else if (firstCategory.separator && secondCategory.separator) {
            let categoryWithNeighbour = {
              id: this.categories[i].categoryId,
              firstCategory: firstCategory,
              secondCategory: null
            }
            pdaCategories.push(categoryWithNeighbour);
            let categoryWithNeighbour2 = {
              id: this.categories[i].categoryId,
              firstCategory: secondCategory,
              secondCategory: null
            }
            pdaCategories.push(categoryWithNeighbour2);
          }
        } else {
          let categoryWithNeighbour = {
            id: this.categories[i].categoryId,
            firstCategory: firstCategory,
            secondCategory: secondCategory
          }
          pdaCategories.push(categoryWithNeighbour);
        }


      }
      return pdaCategories
    }

  },
  methods: {
    getCategoryName(category) {
      if (this.selectedExtraLanguage != null && category.names.translation[this.selectedExtraLanguage] && category.names.translation[this.selectedExtraLanguage].name != null && category.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return category.names.translation[this.selectedExtraLanguage].name;
      }
      return category.names.translation[this.language].name;
    },

    onClickOutside: function (event) {
      if (event.target.id == "categories-modal-container") {
        this.onCloseClick();
      }
    },

    onCloseClick: function (event) {
      this.$store.dispatch("closeCategoriesModal");
    },

    scrollToCategory(anchor) {
      this.$store.dispatch("closeCategoriesModal");
      setTimeout(() => {
        let yOffset = -window.screen.height * 0.08 - 49;

        const y =
          document.getElementById(anchor).getBoundingClientRect()
            .top +
          window.pageYOffset +
          yOffset;

        window.scrollTo({ top: y });

        let el = document.getElementById(`${anchor}-id`);
        const elLeft = el.offsetLeft + el.offsetWidth;
        const elRight = el.offsetLeft;
        const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

        // check if element not in view
        if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
          el.parentNode.scrollLeft =
            elRight - el.parentNode.offsetWidth / 2 - el.offsetWidth / 2;
        } else if (
          elLeft <=
          el.parentNode.offsetLeft + el.parentNode.scrollLeft
        ) {
          el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
        } else if (elRight < el.parentNode.scrollLeft) {
          el.parentNode.scrollLeft =
            elRight - el.parentNode.offsetWidth / 2 + el.offsetWidth;
        }
      }, 65);
    },
  },
};
</script>

<style scoped>
@keyframes slideFromBottom {
  from {
    transform: translateY(90vh);
  }

  to {
    transform: translateY(0);
  }
}

#categories-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 205 !important;
  margin-top: 1vh;
  align-items: flex-start;
  animation: slideFromBottom 0.22s forwards linear;
}

#categories-modal {
  font-family: "Fluter-font" !important;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  min-width: 200px;
  min-height: 450px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  overflow-y: scroll;

  opacity: 1;
  border-radius: 12px 12px 0px 0px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 202 !important;
}

#categories-header-and-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#categories-modal::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#close-categories-modal {
  position: sticky;
  top: 2%;
  left: 93%;
  background: white;
  border-radius: 100%;
  min-height: 4.5vh;
  height: 40% !important;
  width: 4.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#categories-modal-content-container {
  margin-top: 2.5vh;
  margin-bottom: 40px;
}

#categories-of-menu-container {
  display: flex;
  flex-direction: column;
}

.category-of-menu {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.pda-category-of-menu {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category-name {
  color: #141414;
  font-size: 18px;
  margin-bottom: 0px;
}

.pda-category-name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #141414;
  font-size: 18px;
  margin-bottom: 0px;
  width: 50%;
  min-height: 44px;
  border: 1px solid rgb(167, 167, 167);
  box-shadow: 2px 2px 4px rgba(139, 139, 139, 0.36);
  padding: 4px;
  border-radius: 10px;
  word-break: break-word;
}

.width-100 {
  width: 100% !important;
}

.separator-category {
  border-bottom: 2px solid #003340 !important;
  padding-bottom: 22px;
  margin-bottom: 18px;
}

#categories-modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-shrink: 0;
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.36);
  position: sticky;
  top: 0;
}

#categories-title-and-close-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 1285px) {
  #categories-modal {
    width: 100vw;
    height: 100vh;
    min-height: 95%;
  }

  .categories-modal-bigger {
    max-height: 99% !important;
  }

  .modal-smaller {
    max-height: 80% !important;
  }

  #close-categories-modal {
    width: 4.5vh;
    min-height: 4.5vh;
    top: 1%;
    margin-right: 8px;
  }

  h4 {
    font-size: 14px !important;
  }

  #categories-title-name {
    margin: 10px 8px 10px 8px;
    font-size: 22px;
    font-weight: 800;
  }
}

@media (max-width: 360px) {
  #categories-title-name {
    font-size: 20px;
    margin-right: 13.5%;
  }

  h4 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 350px) {
  #close-categories-modal {
    min-height: 3.5vh;
    width: 3.5vh;
    margin-right: 5%;
  }
}

@media (max-width: 300px) {
  #categories-title-name {
    font-size: 18px;
  }
}

@media screen and (max-height: 300px) {
  #categories-modal {
    min-height: 100%;
  }
}
</style>



